@font-face {
	font-family: 'Noto Sans CJK KR';
	font-style: normal;
	font-weight: 100;
	src: url('./fonts/NotoSansKR-Thin.woff2') format('woff2'),
		url('./fonts/NotoSansKR-Thin.woff') format('woff'),
		url('./fonts/NotoSansKR-Thin.otf') format('opentype');
}

@font-face {
	font-family: 'Noto Sans CJK KR';
	font-style: normal;
	font-weight: 300;
	src: url('./fonts/NotoSansKR-Light.woff2') format('woff2'),
		url('./fonts/NotoSansKR-Light.woff') format('woff'),
		url('./fonts/NotoSansKR-Light.otf') format('opentype');
}

@font-face {
	font-family: 'Noto Sans CJK KR';
	font-style: normal;
	font-weight: 400;
	src: url('./fonts/NotoSansKR-Regular.woff2') format('woff2'),
		url('./fonts/NotoSansKR-Regular.woff') format('woff'),
		url('./fonts/NotoSansKR-Regular.otf') format('opentype');
}

@font-face {
	font-family: 'Noto Sans CJK KR';
	font-style: normal;
	font-weight: 500;
	src: url('./fonts/NotoSansKR-Medium.woff2') format('woff2'),
		url('./fonts/NotoSansKR-Medium.woff') format('woff'),
		url('./fonts/NotoSansKR-Medium.otf') format('opentype');
}

@font-face {
	font-family: 'Noto Sans CJK KR';
	font-style: normal;
	font-weight: 700;
	src: url('./fonts/NotoSansKR-Bold.woff2') format('woff2'),
		url('./fonts/NotoSansKR-Bold.woff') format('woff'),
		url('./fonts/NotoSansKR-Bold.otf') format('opentype');
}

@font-face {
	font-family: 'Noto Sans CJK KR';
	font-style: normal;
	font-weight: 900;
	src: url('./fonts/NotoSansKR-Black.woff2') format('woff2'),
		url('./fonts/NotoSansKR-Black.woff') format('woff'),
		url('./fonts/NotoSansKR-Black.otf') format('opentype');
}

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 900;
	src: url('./fonts/Lato-Black.ttf') format('truetype');
}

.ant-modal {
	width: 100% !important;
}

.ant-modal-content {
	background: transparent;
}

.am-grid-item-content {
	position: static !important;
	transform: none !important;
}

body {
	font-family: "Noto Sans CJK KR" !important;
}

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
	bottom: -24px;
}
.swiper-pagination-up {
	bottom: 22px !important;
	margin : auto;
	width: 100%;
}
.swiper-pagination-no {
	display: none;
}
.swiper-pagination-bullet  {
	margin : 0px 4px !important;
}

.swiper-container {
	overflow: visible;
}